import { ElementRef, Injectable } from '@angular/core';
import { DynamicOverlayService } from 'app/main/component/loader/dynamic-overlay.service';
import { BaseService } from 'app/main/services/base.service';
import { environment } from 'environments/environment.uat';
import { BehaviorSubject } from "rxjs";
import { GanttOptions } from './gt-chart/gantt-options';

@Injectable({
  providedIn: 'root'
})
export class GanttChartService {

  apiUrl = environment.basePath;
  pageSize = 20;
  
  private ganttOptionChanged = new BehaviorSubject<GanttOptions>(null);
  public ganttOption = this.ganttOptionChanged.asObservable();


  constructor(
    private baseService: BaseService,
  ) { }

  /**
   * update gantt option
   * @param data : gantt option
   */
  updateGanttOption(data) {
    this.ganttOptionChanged.next(data);
  }

  /**
   * create planned project
   * @param data : payload
   * @returns : Observable
   */
  createProjectPlan(data) {
    return this.baseService.post(this.apiUrl + '/planProjects', true, data);
  }

  /**
   * update planned project
   * @param data: payload
   * @param id : project id
   * @returns : observable
   */
  updateProjectPlan(data, id) {
    return this.baseService.patch(this.apiUrl + '/planProjects/' + id, true, data);
  }

  /**
   * get planned projects based on page size
   * @param currentPage : current page number
   * @param planname : search keyword
   * @returns : observable
   */
  getAllProjectPlans(currentPage, planname) {
    let searchParams = {
      name: planname,
      pageNumber: currentPage,
      pageSize: this.pageSize
    };
    return this.baseService.post(this.apiUrl + '/planProjects/allPlanProject', true, searchParams);
  }

  /**
   * get planned project detail by id
   * @param id : project id
   * @returns : observable
   */
  getProjectPlanById(id) {
    return this.baseService.get(this.apiUrl + '/planProjects/' + id, true);
  }

  /**
   * delete plan project
   * @param id : project id
   * @returns : observable
   */
  deleteProject(id) {
    return this.baseService.delete(this.apiUrl + '/planProjects/' + id, true);
  }

  /**
   * get division/list name based on page size
   * @param currentPage : current page no.
   * @param list : list name to search
   * @returns : observable
   */
  getAllDivisions(currentPage, list) {
    let searchParams = {
      divisionName: list,
      pageNumber: currentPage,
      pageSize: this.pageSize
    };
    return this.baseService.post(this.apiUrl + '/divisionLists/divisions', true, searchParams);
  }

  /**
   * create plan project
   * @param data : payload
   * @returns : observable
   */
  addPlanList(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planList/add', true, data);
  }

  /**
   * update plan project info
   * @param data : payload
   * @returns : observable
   */
  updatePlanList(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planList/updatePlanList', true, data);
  }

  /**
   * delete planned schedule
   * @param data : payload
   * @returns : observable
   */
  deletePlanList(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planList/remove', true, data);
  }

  /**
   * get companies based on page size
   * @param currentPage : current page no.
   * @param searchterm : company name to search
   * @param excludeSelf : exclude logged-in user company name
   * @returns : observable
   */
  getAllCompanies(currentPage, searchterm, excludeSelf: boolean) {
    return this.baseService.get(this.apiUrl + `/companies/list?isExcludeSelf=${excludeSelf}&pageNumber=${currentPage}&pageSize=${this.pageSize}&companyName=${searchterm}`, true);
  }

  /**
   * create trade partner project
   * @param data : payload 
   * @returns : observable
   */
  addTradePartnerProject(data) {
    return this.baseService.post(this.apiUrl + '/planProjects/createTradePartnership', true, data);
  }

  /**
   * update trade partner project
   * @param data : payload 
   * @param id: project id
   * @returns : observable
   */
  updateTradePartnerProject(data, id) {
    return this.baseService.patch(this.apiUrl + '/planProjects/updateTradePartnership/' + id, true, data);
  }

  /**
   * get sub-division/sub-groups by page size
   * @param currentPage : current page no. to fetch
   * @param card : search keyword
   * @param divisionListId : parent division id
   * @returns : observable
   */
  getAllPlanCards(currentPage, card, divisionListId) {
    let searchParams = {
      divisionListId: divisionListId,
      cardName: card,
      pageNumber: currentPage,
      pageSize: this.pageSize
    };
    return this.baseService.post(this.apiUrl + '/divisionCards/cardsList', true, searchParams);
  }

  // addPlanCard(data) {
  //   return this.baseService.patch(this.apiUrl + '/planProjects/planCard/add', true, data);
  // }

  // updatePlanCard(data) {
  //   return this.baseService.patch(this.apiUrl + '/planProjects/planCard/update_planCard_static', true, data);
  // }

  // deletePlanCard(data) {
  //   return this.baseService.patch(this.apiUrl + '/planProjects/planCard/remove', true, data);
  // }

  /**
   * delete planned project's task
   * @param data : payload
   * @returns : observable
   */
  deleteTask(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/planTask/remove', true, data);
  }

  // getPlanCardsForDependency(projectid, pageNo, cardid) {
  //   return this.baseService.get(this.apiUrl + `/planProjects/${projectid}/cards?pageSize=${this.pageSize}&pageNumber=${pageNo}&planCardId=${cardid}`, true);
  // }

  /**
   * get cards for creating dependencies based on page size
   * @param cardid : card id for which dependency to be added
   * @param projectid : project id
   * @param pageNo : current page no.
   * @returns : observable
   */
  getPlanTaskForDependency(cardid, projectid, pageNo) {
    return this.baseService.get(this.apiUrl + `/planProjects/${projectid}/task/cards?pageSize=${this.pageSize}&pageNumber=${pageNo}&planCardId=${cardid}`, true);
  }

  // addPlanCardDependency(data) {
  //   return this.baseService.patch(this.apiUrl + '/planProjects/cards/dependent-task', true, data);
  // }

  /**
   * add dependency for a card
   * @param data : payload
   * @returns : observable
   */
  addPlanTaskDependency(data) {
    return this.baseService.patch(this.apiUrl + '/planProjects/task/cards/dependent-task', true, data);
  }

  // getDependentOnCards(cardid) { //parent cards
  //   return this.baseService.get(this.apiUrl + `/planProjects/planCards/${cardid}/dependent-task`, true);
  // }

  /**
   * get parent cards
   * @param cardid : card id 
   * @returns : observable
   */
  getDependentOnTasks(cardid) { //parent tasks
    return this.baseService.get(this.apiUrl + `/planProjects/task/planCards/${cardid}/dependent-task`, true);
  }

  // deletePlanCardDependency(cardid, depid) {
  //   return this.baseService.patch(this.apiUrl + `/planProjects/planCards/${cardid}/dependent-task/${depid}`, true, null);
  // }

  /**
   * delete planned project's card dependency
   * @param cardid : card id of child
   * @param depid : dependency id
   * @returns : observable
   */
  deletePlanTaskDependency(cardid, depid) {
    return this.baseService.patch(this.apiUrl + `/planProjects/task/planCards/${cardid}/dependent-task/${depid}`, true, null);
  }

  /**
   * freeze planned project
   * @param planprojectid : project id
   * @returns : observable
   */
  freezePlan(planprojectid) {
    return this.baseService.post(this.apiUrl + `/planProjects/freeze-plan-project/${planprojectid}`, true, null);
  }

  /**
   * add task
   * @param data : payload
   * @returns : observable
   */
  addTask(data) {
    return this.baseService.patch(this.apiUrl + `/planProjects/planTask/add`, true, data);
  }

  /**
   * update task
   * @param data : payload 
   * @returns : observable
   */
  updateTask(data) {
    return this.baseService.patch(this.apiUrl + `/planProjects/planTask/updatePlanTask`, true, data);
  }



}
