import { Injectable } from '@angular/core';
import { BaseService } from 'app/main/services/base.service';
import { environment } from 'environments/environment.uat';
import { BehaviorSubject, Observable } from "rxjs";
import { Countries } from './basic-setting-models/countries';

@Injectable({
  providedIn: 'root'
})
export class BasicSettingService {

  apiUrl = environment.basePath;
  public fetchCountries = new BehaviorSubject<Array<Countries>>(null);
  public countries = this.fetchCountries.asObservable();
  private windowScrollEvent$ = new BehaviorSubject(null);
  public tapWindowScrollEvent = this.windowScrollEvent$.asObservable();
  pageSize: number = 30;

  constructor(
    private baseService: BaseService
  ) { }

  /**
   * Method to update subscription value when window is scrolled
   * @param event : any
   */
  windowScrolled(event: any) {
    this.windowScrollEvent$.next(event);
  }

  /**
   * Add Skill
   * @param data : any - payload object
   * @returns {Observable}
   */
  addSkill(data) {
    return this.baseService.post(this.apiUrl + '/skills', true, data);
  }

  /**
   * Get Skills
   * @param pageNo : number - current page no 
   * @param srchString : string - skill search string
   * @returns {Observable}
   */
  getSkills(pageNo, srchString) {
    return this.baseService.get(this.apiUrl + `/skills?pageNumber=${pageNo}&pageSize=${this.pageSize}&skillName=${srchString}`, true);
  }

  /**
   * Update skill
   * @param data : any
   * @returns {Observable}
   */
  updateSkill(data) {
    return this.baseService.patch(this.apiUrl + `/skills`, true, data);
  }

  /**
   * Delete a Skill
   * @param id : string 
   * @returns {Observable}
   */
  deleteSkill(id) {
    return this.baseService.delete(this.apiUrl + `/skills/${id}`, true);
  }

  /**
   * Add Education
   * @param data : any - payload object
   * @returns {Observable}
   */
  addEducation(data) {
    return this.baseService.post(this.apiUrl + '/educations/', true, data);
  }

  /**
   * Get education list
   * @param pageNo : number - current page no 
   * @param srchString : string - education search string
   * @returns {Observable}
   */
  getEducations(pageNo, srchString) {
    return this.baseService.get(this.apiUrl + `/educations?educationName=${srchString}&pageNumber=${pageNo}&pageSize=${this.pageSize}`, true);
  }

  /**
   * Update Education name
   * @param data : any - payload object
   * @returns {Observable}
   */
  updateEducation(data) {
    return this.baseService.patch(this.apiUrl + `/educations`, true, data);
  }

  /**
   * Delete an education
   * @param id : string
   * @returns {Observable}
   */
  deleteEducation(id) {
    return this.baseService.delete(this.apiUrl + `/educations/${id}`, true);
  }

  /**
   * Get countries list
   * @returns {Observable}
   */
  getCountries() {
    return this.baseService.get(this.apiUrl + `/countries`, true);
  }

  /**
   * Get states list by country id
   * @param id : string
   * @returns {Observable}
   */
  getStatesByCountryId(id) {
    return this.baseService.get(this.apiUrl + `/states/${id}`, true);
  }

  /**
   * Add licence
   * @param data :  any - payload object
   * @returns {Observable}
   */
  addLicence(data) {
    return this.baseService.post(this.apiUrl + '/licenses/', true, data);
  }

  /**
   * Get licences list
   * @param pageNo : number - current page no
   * @param data : any - payload object
   * @returns {Observable}
   */
  getLicences(pageNo: number, data: any) {
    return this.baseService.post(this.apiUrl + `/licenses/_search?pageNumber=${pageNo}&pageSize=${this.pageSize}`, true, data);
  }

  /**
   * Delete a licence
   * @param id : string 
   * @returns {Observable}
   */
  deleteLicence(id) {
    return this.baseService.delete(this.apiUrl + `/licenses/${id}`, true);
  }

  /**
   * Update a licence
   * @param data : any - payload object
   * @returns {Observable}
   */
  updateLicence(data) {
    return this.baseService.patch(this.apiUrl + `/licenses/`, true, data);
  }

  /**
   * Add Union 
   * @param data : any - payload object
   * @returns {Observable}
   */
  addUnion(data) {
    return this.baseService.post(this.apiUrl + '/unions', true, data);
  }

  /**
   * Get union list
   * @param pageNo : number - current page no.
   * @param data : any - payload object
   * @returns {Observable}
   */
  getUnions(pageNo: number, data: any) {
    return this.baseService.post(this.apiUrl + `/unions/_search?pageNumber=${pageNo}&pageSize=${this.pageSize}`, true, data);
  }

  /**
   * Delete an union
   * @param id : string
   * @returns {Observable}
   */
  deleteUnion(id) {
    return this.baseService.delete(this.apiUrl + `/unions/${id}`, true);
  }

  /**
   * Update an union
   * @param data : any - payload object
   * @returns {Observable}
   */
  updateUnion(data) {
    return this.baseService.patch(this.apiUrl + `/unions`, true, data);
  }

  /**
   * Check if any users exist in an union
   * @param unionid : string
   * @returns {Observable}
   */
  checkUserInUnion(unionid) {
    return this.baseService.get(this.apiUrl + `/unions/employees/${unionid}`, true);
  }

  /**
   * Check if any users connected to an education name
   * @param eduid : string
   * @returns {Observable}
   */
  checkUserInEducation(eduid) {
    return this.baseService.get(this.apiUrl + `/educations/employees/${eduid}`, true);
  }

  /**
   * Check if any users connected to a licence
   * @param licid : string
   * @returns {Observable}
   */
  checkUserInLnC(licid) {
    return this.baseService.get(this.apiUrl + `/licenses/employees/${licid}`, true);
  }

  /**
   * Check if any users connected to given licence
   * @param skillid : string
   * @returns {Observable}
   */
  checkUserInSkill(skillid) {
    return this.baseService.get(this.apiUrl + `/skills/employees/${skillid}`, true);
  }

  /**
   * Get groups/job roles list
   * @param pageNo : number - current page no.
   * @param srchString : string - group search string
   * @returns {Observable}
   */
  getGroups(pageNo, srchString) {
    return this.baseService.get(this.apiUrl + `/userRoles?pageNumber=${pageNo}&pageSize=${this.pageSize}&roleName=${srchString}`, true);
  }

  /**
   * Add a group
   * @param data : any - payload object
   * @returns {Observable}
   */
  addGroup(data) {
    return this.baseService.post(this.apiUrl + '/userRoles', true, data);
  }

  /**
   * Update group
   * @param data : any - payload object
   * @returns {Observable}
   */
  updateGroup(data) {
    return this.baseService.patch(this.apiUrl + `/userRoles`, true, data);
  }

  /**
   * Delete a group
   * @param id : string
   * @returns {Observable}
   */
  deleteGroup(id) {
    return this.baseService.delete(this.apiUrl + `/userRoles/${id}`, true);
  }
}
