import { PermissionService } from './permission.service';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { DecodedToken } from '../models/decoded-token';
import { takeUntil } from 'rxjs/operators';
import { StakeholderProjectsService } from '../pages/stakeholder-users/pages/stakeholder-projects/stakeholder-projects.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate, OnDestroy {
    private _unsubscribe = new Subject();

    constructor(private permissionService: PermissionService, private router: Router, private authService: AuthService,
        private stakeholderprojectService: StakeholderProjectsService,
    ) {
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let permission = this.permissionService.hasPermission(route.routeConfig.path);

        if (!permission) {
            if (this.authService.isStakeholderUser())
                this.router.navigate(["stakeholder-users"]);
            else if (this.authService.isSubtraidEmployee())
                this.router.navigate(["platform-improvements"]);
            else
                this.router.navigate(["dashboard"]);
        }
        else {
            if (!this.authService.isStakeholderUser() && !this.authService.isSubtraidEmployee() && route.routeConfig.path != 'profile') {
                this.authService.user
                    .pipe(takeUntil(this._unsubscribe))
                    .subscribe(res => {
                        if (res.accountType == 'user' && res.email != "") {
                            if (res.location == "" || res.location == null || (res.location && res.location.length == 0))
                                this.router.navigate(['dashboard', 'profile']);
                        }
                    });
            }
            else if (this.authService.isStakeholderUser()) {
                if (route.routeConfig.path != "projects")
                    this.stakeholderprojectService.exitNavigation();
                // else {
                //     let projectid = state.url.split('/')[4];
                //     if (projectid) {
                //         this.stakeholderprojectService.getStakeholderProjectById(projectid)
                //             .pipe(takeUntil(this._unsubscribe))
                //             .subscribe(res => {
                //                 if (res.board) {
                //                     this.stakeholderprojectService.setProject(res.board);
                //                 }
                //                 else {
                //                     this.stakeholderprojectService.setProject(null);
                //                 }
                //             });
                //     }
                // }
            }
        }

        return permission;
    }
}
